import axios from "axios";
import { CreateDrawRequest, CancelApplicationRequest } from "@/models/api";
import { PostFeedbackRequest } from "@/models/feedback";
import {
  UpdateDisbursementCertifiedAmountRequest,
  UpdateRequestedAmountRequest,
} from "@/models/loanSvc";
import {
  GenerateDocumentViewLinkRequest,
  DownloadDocumentRequest,
  UpdateMembershipMemberIdRequest,
  UpdateConsentStipulationRequest,
  ConfirmEsignRequest,
} from "@/models/api";
import { RefiLoanRequest } from "@/models/refi";
import { PrivacyNotice } from "@/models/disclosures";

class BaseApiService {
  // Since .NET us handling the routing, we don't need to include baseUrl
  // All API calls in controllers will start with {api}
  baseUrl = "api";
  resource: string;

  constructor(resource: string) {
    if (!resource) throw new Error("Resource is not provided");
    this.resource = resource;
  }

  getUrl(id = "") {
    return `${window.location.origin}/${this.baseUrl}/${this.resource}/${id}`;
  }

  handleErrors(err: any): Promise<any> {
    // Note: here you may want to add your errors handling
    console.log("ERROR", { err });
    // add error:true at the end in case message is used elsewhere
    if (err.response?.data && typeof err.response.data === "object") {
      return { ...err.response.data, error: true };
    } else {
      return { ...err, error: true };
    }
  }
}
class AccountApiService extends BaseApiService {
  constructor() {
    super("account");
  }

  async signout(unifiId: string): Promise<any> {
    try {
      const response = await axios(this.getUrl(`signout/${unifiId}`));

      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
}

class ApplicantService extends BaseApiService {
  constructor() {
    super("applicant");
  }

  async getApplicant(applicantId: number) {
    try {
      const response = await axios(this.getUrl(`${applicantId}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getApplicantDocuments(applicantId: number) {
    try {
      const response = await axios(this.getUrl(`${applicantId}/documents`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getApplicantDocument(applicantId: number, documentId: number) {
    try {
      const response = await axios(this.getUrl(`${applicantId}/documents/${documentId}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async addAddress(applicantId: number, body: any) {
    try {
      const response = await axios.post(this.getUrl(`${applicantId}/address`), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getIncomeSources(refId: string) {
    try {
      const response = await axios(this.getUrl(`Ref/${refId}/IncomeSources`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async updateIncomeSources(body: any) {
    try {
      const response = await axios.put(this.getUrl(`IncomeSources`), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async updateApplicantMembershipMemberId(body: UpdateMembershipMemberIdRequest): Promise<any> {
    try {
      const response = await axios.put(this.getUrl(`memberId`), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async calculateApplicantAge(body: any) {
    try {
      const response = await axios.post(this.getUrl("calculateAge"), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async updateApplicantConsentStipulation(body: any): Promise<any> {
    try {
      const response = await axios.post(this.getUrl(`Consent/Stipulation`), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getApplicantSurveyResponses(applicantId: number) {
    try {
      const response = await axios(this.getUrl(`${applicantId}/SurveyResponses`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async sendApplicantPrivacyNoticeRequest(
    applicantId: number,
    applicationId: number,
    body: PrivacyNotice[],
  ): Promise<any> {
    try {
      const response = await axios.post(
        this.getUrl(`${applicantId}/application/${applicationId}/privacy`),
        body,
      );
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
}

class ApplicationsService extends BaseApiService {
  constructor() {
    super("applications");
  }
  async cancelApplication(refId: string, lenderId: number, body: CancelApplicationRequest) {
    try {
      const response = await axios.post(
        this.getUrl(`${refId}/${lenderId}/CancelApplication`),
        body,
      );
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getUserApplications(unifiId: string): Promise<any> {
    try {
      const response = await axios(this.getUrl(unifiId));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getUserApplication(unifiId: string, appId: string): Promise<any> {
    try {
      const response = await axios(this.getUrl(`${unifiId}/${appId}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getUserApplicationByRefId(unifiId: string, referenceId: string, lid: number): Promise<any> {
    try {
      const response = await axios(this.getUrl(`${unifiId}/${referenceId}/${lid}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async updateApplication(unifiId: string, appId: number, body: any) {
    try {
      const response = await axios.patch(this.getUrl(`${unifiId}/${appId}`), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getApplicationInvites(appId: number) {
    try {
      const response = await axios(this.getUrl(`${appId}/invites`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async addOrUpdateInitialDraw(appId: number, body: any) {
    try {
      const response = await axios.put(this.getUrl(`${appId}/initialDraw`), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getInitialDraw(appId: number) {
    try {
      const response = await axios(this.getUrl(`${appId}/initialDraw`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async addOrUpdateAppLoanConfig(body: any) {
    try {
      const response = await axios.post(this.getUrl("loanConfig"), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getAppLoanConfig(appId: number) {
    try {
      const response = await axios(this.getUrl(`${appId}/loanConfig`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getApplicationRatePlanByRatePlanId(appId: number, ratePlanId: number) {
    try {
      const response = await axios(this.getUrl(`${appId}/RatePlans/${ratePlanId}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getApplicationRatePlansByApplicationId(appId: number, lid: number) {
    try {
      const response = await axios(this.getUrl(`${appId}/RatePlans?LID=${lid}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getApplicants(unifiId: string, appId: number) {
    try {
      const response = await axios(this.getUrl(`${unifiId}/${appId}/applicants`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getApplicant(applicantId: number) {
    try {
      const response = await axios(this.getUrl(`applicant/${applicantId}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getApplicantDocuments(applicantId: number) {
    try {
      const response = await axios(this.getUrl(`applicant/${applicantId}/documents`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getApplicantDocument(applicantId: number, documentId: number) {
    try {
      const response = await axios(this.getUrl(`applicant/${applicantId}/documents/${documentId}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getApplicantStips(applicationId: number, applicantId: number) {
    try {
      const response = await axios(this.getUrl(`${applicationId}/applicants/${applicantId}/stips`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getApplicationStips(applicationId: number) {
    try {
      const response = await axios(this.getUrl(`${applicationId}/stips`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async addStipInput(appId: number, body: any) {
    try {
      const response = await axios.post(this.getUrl(`${appId}/AddStipInput`), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async resendInvite(appId: number, body: any) {
    try {
      const response = await axios.post(this.getUrl(`${appId}/invite`), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getRequiredDocuments(body: any) {
    try {
      const response = await axios.post(this.getUrl(`required-documents`), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getLoans(referenceId: string, lenderId: number) {
    try {
      const response = await axios.get(this.getUrl(`${referenceId}/loan`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async generateSigningUrl(applicationId: number, docTypeId: number) {
    try {
      const response = await axios.get(
        this.getUrl(`${applicationId}/Documents/SigningUrl?docTypeId=${docTypeId}`),
      );
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async updateApplicantStipulation(
    applicationId: number,
    applicantId: number,
    stipulationId: number,
    body: any,
  ) {
    try {
      const response = await axios.put(
        this.getUrl(`${applicationId}/${applicantId}/${stipulationId}`),
        body,
      );
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async updateApplicationStipulation(applicationId: number, stipulationId: number, body: any) {
    try {
      const response = await axios.put(this.getUrl(`${applicationId}/${stipulationId}`), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async updateRequestedAmount(body: UpdateRequestedAmountRequest) {
    try {
      const response = await axios.post(this.getUrl("draw/requestedAmount"), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async updateDisbursementCertifiedAmount(body: UpdateDisbursementCertifiedAmountRequest) {
    try {
      const response = await axios.post(this.getUrl("disbursement/certifiedAmount"), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async checkRTCPeriod(referenceId: string) {
    try {
      const response = await axios.get(this.getUrl(`${referenceId}/RightToCancelPeriod`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getPayoffDisbursementStatus(loanId: number) {
    try {
      const response = await axios.get(this.getUrl(`payoffStatus/${loanId}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
}

class ConsentsService extends BaseApiService {
  constructor() {
    super("consents");
  }
  async addConsent(body: any) {
    try {
      const response = await axios.post(this.getUrl(""), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getAllConsents(applicantId: number) {
    try {
      const response = await axios(this.getUrl(`applicants/${applicantId}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async updateConsent(body: any) {
    try {
      const response = await axios.put(this.getUrl(""), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getPrivacyNoticeDetails(applicationId: number) {
    try {
      const response = await axios(this.getUrl(`application/${applicationId}/privacy-details`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
}

class DocumentsService extends BaseApiService {
  constructor() {
    super("documents");
  }

  // Get adverse action notice
  async getAdverseActionNotice(applicationtId: number) {
    try {
      const response = await axios(this.getUrl(`adverseActionNotice`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
  // GET Document by applicationID and docType
  async getApplicationDocumentByType(applicationtId: number, type: number) {
    try {
      const response = await axios(this.getUrl(`application/${applicationtId}/type/${type}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
  // GET DOCUMENT BY DOCUMENT ID
  async getDocument(documentId: number) {
    try {
      const response = await axios(this.getUrl(`document/${documentId}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
  // Applicant Documents
  async getApplicantDocuments(applicantId: number) {
    try {
      const response = await axios(this.getUrl(`applicant?applicantId=${applicantId}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
  // Application Documents
  async getApplicationDocuments(applicationId: number) {
    try {
      const response = await axios(this.getUrl(`application?applicationId=${applicationId}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getApplicationDocumentsByRefId(refId: string) {
    try {
      const response = await axios(this.getUrl(`application/${refId}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getGeneratedDocumentsByRefId(refId: string) {
    try {
      const response = await axios(this.getUrl(`application/${refId}/docs`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async downloadDocument(body: DownloadDocumentRequest) {
    try {
      const response = await axios.post(this.getUrl(`application/document/download`), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async generateDocumentViewLink(body: GenerateDocumentViewLinkRequest) {
    try {
      const response = await axios.post(this.getUrl("generateDocumentViewLink"), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
}

class ConfigurationApiService extends BaseApiService {
  constructor() {
    super("configuration");
  }

  async getLenderConfiguration(
    programTypeId: number,
    productTypeId: number,
    lid: number,
  ): Promise<any> {
    try {
      const response = await axios(
        this.getUrl(`programType/${programTypeId}/productType/${productTypeId}/lender/${lid}`),
      );

      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
}

class LookupsApiService extends BaseApiService {
  constructor() {
    super("lookups");
  }

  async getLookups() {
    try {
      const response = await axios(this.getUrl(""));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getSchoolInfoLookups(programTypeId?: number) {
    try {
      const response = await axios(this.getUrl(`school-info?programTypeId=${programTypeId}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
  async getEnrollmentTermsLookups() {
    try {
      const response = await axios(this.getUrl(`school-confirmation`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getStipsLookups() {
    try {
      const response = await axios(this.getUrl(`stips-info`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getDocInfoLookups() {
    try {
      const response = await axios(this.getUrl(`document-info`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getConsentTypes() {
    try {
      const response = await axios(this.getUrl(`consent-types`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
}

class OriginatorApiService extends BaseApiService {
  constructor() {
    super("originators");
  }

  async getStates() {
    try {
      const response = await axios(this.getUrl("state-info"));

      return response.data || [];
    } catch (err) {
      return this.handleErrors(err).catch(error => console.log(error));
    }
  }

  async getEnrollments() {
    try {
      const response = await axios(this.getUrl("enrollment-info"));

      return response.data || [];
    } catch (err) {
      return this.handleErrors(err).catch(error => console.log(error));
    }
  }

  async getSchools(programTypeId: number, productTypeId: number, lenderId: number) {
    try {
      const url = this.getUrl(`school-info`);
      const response = await axios.get(url, {
        params: {
          programTypeId: programTypeId,
          productTypeId: productTypeId,
          lenderId: lenderId,
        },
      });

      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async postDrawRequest(referenceId: any, body: CreateDrawRequest): Promise<any> {
    try {
      const response = await axios.post(this.getUrl(`${referenceId}/Draws/Create`), body);

      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async loanHasBeenBoarded(referenceId: any) {
    try {
      const response = await axios.get(this.getUrl(`${referenceId}/boarded`));

      return response.data || false;
    } catch (err) {
      return this.handleErrors(err);
    }
  }
}

class ProgramPricingService extends BaseApiService {
  constructor() {
    super("programpricing");
  }

  async incomeLimitCheck(
    lenderId: number,
    programTypeId: number,
    productTypeId: number,
    limitTypeId: number,
    dateToCheck: Date,
  ) {
    try {
      const response = await axios(
        this.getUrl(
          `lender/${lenderId}/programType/${programTypeId}/productType/${productTypeId}/limitTypeId/${limitTypeId}/date/${dateToCheck}`,
        ),
      );

      return response.data;
    } catch (err) {
      return this.handleErrors(err).catch(error => console.log(error));
    }
  }
}

class ReturnService extends BaseApiService {
  constructor() {
    super("return");
  }

  async getReturnLink(url: string, applicationId: number): Promise<any> {
    try {
      const response = await axios.get(`${url}/api/return?applicationId=${applicationId}`);
      return response || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getRedirect(url: string, applicationId: number) {
    try {
      const response = await axios(`${url}/return?applicationId=${applicationId}`);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
}

class SchoolService extends BaseApiService {
  constructor() {
    super("schools");
  }

  async getSchools() {
    try {
      const response = await axios(this.getUrl(""));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getSchoolsByProgram(programTypeId: number, productTypeId: number, lenderId: number) {
    try {
      const url = this.getUrl(`program/${encodeURIComponent(programTypeId)}`);
      const response = await axios.get(url, {
        params: {
          productTypeId: productTypeId,
          lenderId: lenderId,
        },
      });

      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
  async getSchool(schoolId: number) {
    try {
      const response = await axios(this.getUrl(`${schoolId}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
}

class FeedbackService extends BaseApiService {
  constructor() {
    super("feedback");
  }

  async sendFeedback(body: PostFeedbackRequest) {
    try {
      const response = await axios.post(this.getUrl(""), body);
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getFeedbackFormOptions(formId: number) {
    try {
      const response = await axios.get(this.getUrl(`${formId}`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
}

class RefiService extends BaseApiService {
  constructor() {
    super("refi");
  }

  async getRefiLoans(applicationId: number) {
    try {
      const response = await axios.get(this.getUrl(`application/${applicationId}/Loans`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getRefiDetails(applicationId: number) {
    try {
      const response = await axios.get(this.getUrl(`application/${applicationId}/Details`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getRefiRatePlans(applicationId: number) {
    try {
      const response = await axios.get(this.getUrl(`application/${applicationId}/RatePlans`));
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async updateRefiLoans(applicationId: number, applicantId: number, body: Array<RefiLoanRequest>) {
    try {
      const response = await axios.put(
        this.getUrl(`application/${applicationId}/applicant/${applicantId}/loans`),
        body,
      );
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async confirmEsign(body: ConfirmEsignRequest) {
    try {
      const response = await axios.post(this.getUrl(`Confirm/Esign`), body);
      return response || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
}

export const $api = {
  account: new AccountApiService(),
  applicant: new ApplicantService(),
  applications: new ApplicationsService(),
  configuration: new ConfigurationApiService(),
  consents: new ConsentsService(),
  documents: new DocumentsService(),
  lookups: new LookupsApiService(),
  programpricing: new ProgramPricingService(),
  originator: new OriginatorApiService(),
  return: new ReturnService(),
  school: new SchoolService(),
  feedback: new FeedbackService(),
  refi: new RefiService(),
};
