import { Module } from "vuex";
import { $api } from "@/services/api";
import { Applicant } from "@/models/application";
import { ConsentTypeIds, ApplicantTypeIds } from "@/config/constants";
import { ConsentFormRequest } from "@/models/api";
import store from "@/store";

const consentsModule: Module<any, any> = {
  namespaced: true,
  state: {
    consentTypes: [] as any[],
    currentApplicant: {} as Applicant,
    primaryApplicantConsents: [],
    coApplicantConsents: [],
    SLRPayoffConfirmationConsent: undefined as undefined | boolean,
    SLRPayoffConfirmationConsentId: undefined as undefined | boolean,
  },
  mutations: {
    RESET_STATE(state: any) {
      state = {
        consentTypes: [] as any[],
        currentApplicant: {} as Applicant,
        primaryApplicantConsents: [],
        coApplicantConsents: [],
        SLRPayoffConfirmationConsent: undefined as undefined | boolean,
        SLRPayoffConfirmationConsentId: undefined as undefined | boolean,
      };
    },
    SET_CURRENT_APPLICANT(state: any, applicant: Applicant) {
      state.currentApplicant = applicant;
    },
    SET_CONSENT_TYPES(state: any, consentTypes: any) {
      state.consentTypes = consentTypes;
    },
    SET_PRIMARY_APPLICANT_CONSENTS(state: any, consents: any) {
      state.primaryApplicantConsents = consents;
    },
    SET_CONSENTS(state: any, consents: any) {
      if (state?.currentApplicant?.applicantTypeId === ApplicantTypeIds.PRIMARY_APPLICANT) {
        state.primaryApplicantConsents = consents;
      } else if (state?.currentApplicant?.applicantTypeId === ApplicantTypeIds.CO_APPLICANT) {
        state.coApplicantConsents = consents;
      } else {
        state.primaryApplicantConsents = [];
        state.coApplicantConsents = [];
      }
    },
    SET_CO_APPLICANT_CONSENTS(state: any, consents: any) {
      state.coApplicantConsents = consents;
    },
    SET_SLR_PAYOFF_CONFIRMATION_CONSENT_ID(state: any, id?: number) {
      state.SLRPayoffConfirmationConsentId = id;
    },
    SET_SLR_PAYOFF_CONFIRMATION_CONSENT(state: any, consent: boolean) {
      state.SLRPayoffConfirmationConsent = consent;
    },
  },
  actions: {
    setCurrentApplicantId({ commit }, payload) {
      commit("SET_CURRENT_APPLICANT_ID", payload);
    },
    setCurrentApplicant({ commit }, payload) {
      commit("SET_CURRENT_APPLICANT", payload);
    },
    async saveConsentForm({ state, commit }, payload) {
      let consents = [];
      if (payload?.id && payload?.id > 0) {
        consents = await $api.consents.updateConsent(payload);
      } else {
        consents = await $api.consents.addConsent(payload);
      }

      if (consents && consents?.length > 0) {
        commit("SET_CONSENTS", consents);
      }
    },
    async getConsentsByApplicantId({ state, commit, dispatch }, payload) {
      const applicantId = payload || 0;
      const consents = await $api.consents.getAllConsents(applicantId);
      if (consents && consents?.length > 0) {
        commit("SET_CONSENTS", consents);
      }
    },
    async getConsentsForAllApplicants({ state, dispatch }) {
      store?.state?.appData?.applicants?.forEach((a: Applicant) => {
        dispatch("getConsentsByApplicantId", a.id);
      });
    },
    async getConsentTypes({ state, commit }) {
      try {
        if (state.consentTypes?.length <= 0) {
          const lookups = await $api.lookups.getConsentTypes();
          if (lookups?.length > 0) {
            commit("SET_CONSENT_TYPES", lookups);
          }
        }
      } catch (error) {
        console.log("Error: getConsentTypes()", error);
      }
    },
    async saveSLRPayoffConfirmationConsent({ state, getters, dispatch }, payload) {
      dispatch("getConsentTypes");

      const SLRPayoffConfirmationConsentForm = {
        id: state.SLRPayoffConfirmationConsentId,
        consentTypeId: getters["SLRPayoffConfirmationConsentTypeId"],
        consentGiven: payload,
        applicantId: state.currentApplicant?.id || 0,
        applicationId: state.currentApplicant?.applicationId || 0,
        userId: state.currentApplicant?.userId,
      } as ConsentFormRequest;

      await dispatch("saveConsentForm", SLRPayoffConfirmationConsentForm);
    },
  },
  getters: {
    Consents: (state: any): any => {
      if (state?.currentApplicant?.applicantTypeId === ApplicantTypeIds.PRIMARY_APPLICANT) {
        return state.primaryApplicantConsents;
      } else if (state?.currentApplicant?.applicantTypeId === ApplicantTypeIds.CO_APPLICANT) {
        return state.coApplicantConsents;
      } else {
        return [];
      }
    },
    SLR_PAYOFF_CONFIRMATION_EXISTS(state: any) {
      const consentTypeId = ConsentTypeIds.SLR_PAYOFF_CONFIRMATION;
      const primaryApplicantSLRPayoffConfirmationConsent = state.primaryApplicantConsents?.find(
        (q: any) => q.consentTypeId === consentTypeId,
      );
      const coApplicantSLRPayoffConfirmationConsent = state.coApplicantConsents?.find(
        (q: any) => q.consentTypeId === consentTypeId,
      );

      if (primaryApplicantSLRPayoffConfirmationConsent || coApplicantSLRPayoffConfirmationConsent) {
        if (store.state.appData.application.metadata.withCoApplicants) {
          return (
            primaryApplicantSLRPayoffConfirmationConsent || coApplicantSLRPayoffConfirmationConsent
          );
        }
        else {
          return (
            primaryApplicantSLRPayoffConfirmationConsent?.id &&
            primaryApplicantSLRPayoffConfirmationConsent?.consentGiven
          );
        }
      } else {
        return false;
      }
    },
    SLRPayoffConfirmationConsentTypeId: (state: any): number => {
      return state?.consentTypes?.find(
        (ct: any) => ct.id === ConsentTypeIds.SLR_PAYOFF_CONFIRMATION,
      )?.id;
    },
  },
};

export default consentsModule;
