import { Module } from "vuex";
import { $api } from "@/services/api";
import store from "@/store";
import { RefiRatePlanViewModel, RefiRateViewModel } from "@/models/refi";
import { LoanConfig } from "@/models/application";

const refiLoanConfigurationModule: Module<any, any> = {
  namespaced: true,
  state: {
    refiRatePlans: [] as RefiRatePlanViewModel[],
    refiLoanConfiguration: {} as LoanConfig,
    selectedRateTerm: 0 as number,
    selectedRateType: "" as string,
    selectedRatePlanId: 0 as number,
    selectedRatePlan: {} as RefiRatePlanViewModel,
    selectedRateOption: {} as RefiRateViewModel,
    availableTerms: [] as number[],
    updateLoanConfigurationError: false as boolean,
    updatingLoanConfiguration: false as boolean,
    updateLoanConfigurationErrorText: "" as string,
    selectedRepaymentTypeId: 0 as number,
  },
  mutations: {
    RESET_STATE(state: any) {
      state = {
        refiRatePlans: [],
        refiLoanConfiguration: {} as LoanConfig,
        selectedRateTerm: 0 as number,
        selectedRateType: "" as string,
        selectedRatePlanId: 0 as number,
        selectedRatePlan: {} as RefiRatePlanViewModel,
        selectedRateOption: {} as RefiRateViewModel,
        availableTerms: [] as number[],
        updateLoanConfigurationError: false as boolean,
        updatingLoanConfiguration: false as boolean,
        updateLoanConfigurationErrorText: "" as string,
        selectedRepaymentTypeId: 0 as number,
      };
    },
    SET_UPDATING_LOAN_CONFIGURATION(state: any, flag: boolean) {
      state.savingChanges = flag;
    },
    SET_LOAN_CONFIGURATION_UPDATE_ERROR(state: any, flag: boolean) {
      state.updateLoanConfigurationError = flag;
    },
    SET_LOAN_CONFIGURATION_UPDATE_ERROR_TEXT(state: any, error: string) {
      state.updateLoanConfigurationErrorText = error;
    },
    SET_SELECTED_RATE_TYPE(state: any, rateType: string) {
      state.selectedRateType = rateType;
    },
    SET_SELECTED_REPAYMENT_TYPE_ID(state: any, repaymentTypeId: number) {
      state.selectedRepaymentTypeId = repaymentTypeId;
    },
    SET_AVAILABLE_TERMS(state: any, terms: any[]) {
      state.availableTerms = terms;
    },
    SET_REFI_RATE_PLANS(state: any, ratePlans: RefiRatePlanViewModel[]) {
      state.refiRatePlans = ratePlans;
    },
    SET_SELECTED_RATE_TERM(state: any, rateTerm: number) {
      state.selectedRateTerm = rateTerm;
    },
    SET_SELECTED_RATE_PLAN(state: any, selectedRatePlan: RefiRatePlanViewModel) {
      state.selectedRatePlan = selectedRatePlan;
    },
    SET_SELECTED_RATE_PLAN_ID(state: any, selectedRatePlanId: number) {
      state.selectedRatePlanId = selectedRatePlanId;
    },
    SET_SELECTED_RATE_OPTION(state: any, selectedRateOption: RefiRateViewModel) {
      state.selectedRateOption = selectedRateOption;
    },
    SET_REFI_LOAN_CONFIGURATION(state: any, loanConfiguration: LoanConfig) {
      state.refiLoanConfiguration = loanConfiguration;
    },
    RESET_REFI_LOAN_CONFIGURATION_MODAL(state: any) {
      state.updateLoanConfigurationError = false;
      state.updatingLoanConfiguration = false;
    },
  },
  actions: {
    resetState({ commit }) {
      commit("RESET_STATE");
    },
    resetRefiLoanConfigurationModal({ commit }) {
      commit("RESET_REFI_LOAN_CONFIGURATION_MODAL");
    },
    setSelectedRatePlan({ commit }, payload) {
      commit("SET_SELECTED_RATE_PLAN", payload);
    },
    setSelectedRateOption({ commit }, payload) {
      commit("SET_SELECTED_RATE_OPTION", payload);
    },
    setSelectedRatePlanId({ commit }, payload) {
      commit("SET_SELECTED_RATE_PLAN_ID", payload);
    },
    setSelectedRateTerm({ commit }, payload) {
      commit("SET_SELECTED_RATE_TERM", payload);
    },
    setRefiLoanConfiguration({ commit }, payload) {
      commit("SET_REFI_LOAN_CONFIGURATION", payload);
    },
    setSelectedRateType({ commit }, payload) {
      commit("SET_SELECTED_RATE_TYPE", payload);
    },
    async getRefiRatePlans({ state, commit }, payload) {
      try {
        const refiRatePlansResponse = await $api.refi.getRefiRatePlans(payload);
        if (refiRatePlansResponse && refiRatePlansResponse?.ratePlans?.length > 0) {
          commit("SET_REFI_RATE_PLANS", refiRatePlansResponse?.ratePlans);
          commit("SET_SELECTED_REPAYMENT_TYPE_ID", refiRatePlansResponse?.repaymentTypeId);
          // Set selected rate option
          const selectedRatePlanOption = refiRatePlansResponse?.ratePlans.find(
            (r: RefiRatePlanViewModel) => r.isSelected,
          );
          if (selectedRatePlanOption) {
            const selectedRate = selectedRatePlanOption.selectedRate;

            commit("SET_SELECTED_RATE_PLAN_ID", selectedRate.id);
          }
        } else {
          commit("SET_REFI_RATE_PLANS", []);
        }
      } catch (error) {
        console.log("Error: getRefiRatePlans()", error);
      }
    },
    async updateLoanConfiguration({ state, commit }) {
      commit("SET_UPDATING_LOAN_CONFIGURATION", true);

      const appLoanConfigRequest = {
        applicationId: store.state.appData.application.id,
        selectedRatePlanId: state?.selectedRatePlanId,
        selectedByApplicantId:
          store?.state?.appData?.applicants[store?.state?.appData?.applicants?.length - 1]?.id,
        repaymentTypeId: state?.selectedRepaymentTypeId,
      };

      const response = await $api.applications.addOrUpdateAppLoanConfig(appLoanConfigRequest);
      if (response && response?.applicationId) {
        store.dispatch("updateLoanConfiguration", response);
        commit("SET_REFI_LOAN_CONFIGURATION", response);
        commit("SET_LOAN_CONFIGURATION_UPDATE_ERROR", false);
        commit("SET_UPDATING_LOAN_CONFIGURATION", false);
        return true;
      } else {
        commit(
          "SET_LOAN_CONFIGURATION_UPDATE_ERROR_TEXT",
          "An error occurred while processing your request. Please try again later. If the issue persists, please contact our support team.",
        );
        commit("SET_LOAN_CONFIGURATION_UPDATE_ERROR", true);
        return false;
      }
    },
  },
  getters: {
    SelectedLoanConfiguration: (state: any): any => {
      return {
        selectedLoanTerm: `${state.selectedRateTerm} Year Term`,
        selectedInterestRate: state?.selectedRateOption?.rate || "Not Yet Selected",
        selectedRateTypeName: state?.selectedRateType,
        estimatedMonthlyLoanPayment: state.selectedRateOption?.estimatedPayment,
      };
    },
    AvailableRatePlans: (state: any): RefiRatePlanViewModel[] => {
      return state.refiRatePlans;
    },
    StyleOption: (state: any): number => {
      const fixedAndVariable = [];
      const singleRateTypeOffered = [];
      const availableRatePlans = state.refiRatePlans;
      availableRatePlans?.forEach(function (ratePlan: any) {
        // Single rate type offered
        if (
          (ratePlan.hasFixedRate && !ratePlan.hasVariableRate) ||
          (!ratePlan.hasFixedRate && ratePlan.hasVariableRate)
        ) {
          singleRateTypeOffered.push(ratePlan.year);
        }
        // check if each plan offers BOTH fixed and variable
        if (ratePlan.hasFixedRate && ratePlan.hasVariableRate) {
          fixedAndVariable.push(ratePlan.year);
        }
      });

      return fixedAndVariable?.length > 0 && singleRateTypeOffered?.length > 0 ? 1 : 2;
    },
    FormattedTerms: (state: any): string => {
      return state.availableTerms?.map((n: any, index: any) => {
        return index === state.availableTerms?.length - 1 ? `or ${n}-year` : `${n}- ,`;
      });
    },
  },
};

export default refiLoanConfigurationModule;
