
import { defineComponent, computed, reactive, ref } from "vue";
import { mapState, mapActions, useStore, mapGetters } from "vuex";
import * as jsonPatch from "fast-json-patch";
import { Application, LoanConfig } from "@/models/application";
import { School } from "@/models/school";
import { EnrollmentTerm, InitialFundsRequest } from "@/models/opal";
import { $api } from "@/services/api";
import useFormatter from "@/use/formatter";
import Tile from "@/components/shared/Tile.vue";
import SchoolConfirmation from "@/components/loan-configuration/SchoolConfirmation.vue";
import BorrowAmount from "@/components/loan-configuration/BorrowAmount.vue";
import LoanOptions from "@/components/loan-configuration/LoanOptions.vue";
import usePaymentOptions from "@/use/paymentOptions";
import TimeoutModal from "@/components/modals/TimeoutModal.vue";
import RequestFundsModalOpal from "@/components/modals/RequestFundsModalOpal.vue";
import NextButton from "@/components/shared/NextButton.vue";
import Error from "@/components/shared/Error.vue";
import CircleLoader from "@/components/shared/CircleLoader.vue";
import OpalModal from "@/components/shared/OpalModal.vue";

export default defineComponent({
  props: {
    application: {
      type: Object,
      required: true,
    },
    applicant: {
      type: Object,
      required: true,
    },
  },
  emits: ["edit", "previous", "complete", "getLoanDetails", "hydrateStore"],
  setup(props, { emit }) {
    const store = useStore();
    // Computed
    const isComplete = computed(
      () =>
        props.application?.applicationStatusName?.toString().toLowerCase().trim() === "complete",
    );

    if (!props?.applicant?.id) {
      emit("hydrateStore", true);
    }

    const showRequestFundsButton = computed(() => isComplete.value === true);
    const legalPacketGenerated = computed(() => store.state.legalPacketGenerated);
    const enrollmentStatusName = computed(() => store.getters["fundsModule/EnrollmentStatusName"]);
    // Reactive
    const info = reactive({}) as any;
    const initialDrawRequest = reactive({} as InitialFundsRequest);
    const loanConfiguration = reactive<LoanConfig>({} as LoanConfig);
    const schoolInfo = reactive({ school: {} as School });
    const selectedRatePlan = reactive({} as any);
    const appObserver = reactive({} as jsonPatch.Observer<Application>);
    // Ref
    const applicationStatus =
      ref<string>(props?.application?.applicationStatusName?.trim().toLowerCase()) || "approved";
    const fundsRequestBtnText = ref<string>("Request Funds");
    const showFundsRequestModal = ref<boolean>(false);
    const showSchoolConfirmationModal = ref<boolean>(false);
    const showBorrowAmountModal = ref<boolean>(false);
    const showLoanOptionsModal = ref<boolean>(false);
    const showLoanConfirmationModal = ref<boolean>(false);
    const showTimeoutModal = ref<boolean>(false);
    const showAlertModal = ref<boolean>(false);
    const updateBorrowAmount = ref<boolean>(false);
    const updateLoanOptions = ref<boolean>(false);
    const updateSchoolInfo = ref<boolean>(false);
    const updateLoanConfirmation = ref<boolean>(false);
    const loading = ref<boolean>(true);
    const gettingData = ref<boolean>(true);
    const error = ref<boolean>(false);
    const loanSvcError = ref<boolean>(false);
    const allowZero = ref<boolean | undefined>();
    const modalsClosed = ref<boolean>(true);
    const loanExists = ref<boolean | undefined>();

    const sortedApplicationStips = computed(
      () => store.state.checklistModule.sortedApplicationStips,
    );
    const rateAndRepaymentStipsExist = computed(() => {
      const keys = Object.keys(sortedApplicationStips.value);

      if (keys.includes("Rate and Repayment")) {
        return sortedApplicationStips.value["Rate and Repayment"]?.length > 0;
      } else {
        return false;
      }
    });
    // Methods
    const { getPaymentOptionHeader, getRateTypeName } = usePaymentOptions();
    const { formatMoney } = useFormatter();
    function previous(event: any) {
      emit("previous", event);
    }
    function edit(event: any) {
      emit("edit", event);
    }
    function handleBorrowAmountModal() {
      if (
        store.state.appData.application.schoolConfiguration.schoolId &&
        store.state.appData.application.schoolConfiguration?.academicTerm
      ) {
        showBorrowAmountModal.value = true;
      } else {
        store.dispatch("modal/openModal", {
          title: "Alert",
          description:
            "Please select your school, academic term, and anticipated graduation date first before you select your requested amount.",
          allowBackgroundScroll: false,
        });
      }
    }
    async function getData() {
      if ((store.state.schools || []).length <= 0) {
        const res = await $api.school.getSchoolsByProgram(
          store?.state?.appData?.application?.programTypeId,
          store?.state?.appData?.application?.productTypeId,
          store?.state?.appData?.application?.lenderId,
        );
        store.dispatch("setSchools", res.schools);
      }
      if ((store.state.enrollmentTerms || []).length <= 0) {
        const res = await $api.lookups.getEnrollmentTermsLookups();
        store.dispatch("setEnrollmentTerms", res);
      }
    }
    async function getLastRequestedDraw(loan: any) {
      if (loan?.draws?.length === 0) {
        // Handle requested amount
        info.requestedAmount = formatMoney(Number(0.0)) || "Not Yet Selected";

        // Handle school
        const schoolId = store.state?.appData?.application?.schoolConfiguration?.schoolId;
        info.school = store.state.schools.find((s: School) => s.id === schoolId);
        info.schoolName = info.school ? info.school.schoolName : "Not Yet Selected";
        if (info.school) {
          store.commit("fundsModule/setDrawModalSchool", info.school);
        }

        // Handle AGD
        const graduationDate =
          store.state?.appData?.application?.schoolConfiguration?.graduationDate;
        if (graduationDate && info.schoolName !== "Not Yet Selected") {
          const date = new Date(graduationDate);
          store.commit("fundsModule/setGraduationDate", date);
        }

        // Handle enrollment term
        const enrollmentTerm = store.state.enrollmentTerms.find(
          (e: EnrollmentTerm) =>
            e.description === store.state?.appData?.application?.schoolConfiguration?.academicTerm,
        );
        if (enrollmentTerm) {
          store.commit("fundsModule/setFundingTerm", enrollmentTerm);
        }

        // Handle Enrollment Status
        const enrollmentStatus =
          store.state?.appData?.application?.schoolConfiguration?.enrollmentStatus;
        if (enrollmentStatus) {
          store.commit("fundsModule/setEnrollmentStatus", enrollmentStatus);
        }
      }

      if (loan?.draws?.length > 0) {
        // Handle requested amount
        if (loan?.draws && loan?.draws?.[loan?.draws?.length - 1]?.requestedAmount >= 0) {
          info.requestedAmount =
            formatMoney(loan?.draws?.[loan?.draws?.length - 1]?.requestedAmount) ||
            formatMoney(Number(0.0));
          if (loan?.draws?.[loan?.draws?.length - 1]?.requestedAmount > 0) {
            fundsRequestBtnText.value = "Request Additional Funds";
          }
        }

        await getData();

        // Handle school
        const schoolDOEId = loan?.draws?.[loan?.draws?.length - 1]?.schoolCode;
        const schoolBranchDOEId = loan?.draws?.[loan?.draws?.length - 1]?.schoolBranch;

        info.school = store.state.schools.find(
          (s: School) => s.schoolDOEId === schoolDOEId && s.schoolBranchDOEId === schoolBranchDOEId,
        );

        info.schoolName = info.school ? info.school.schoolName : "Not Yet Selected";

        // Handle Anticipated Graduation Date
        const gradDate = loan?.draws?.[loan?.draws?.length - 1]?.anticipatedGraduationDate;
        if (gradDate && info.schoolName !== "Not Yet Selected") {
          const date = new Date(gradDate);
          store.commit("fundsModule/setGraduationDate", date);
        }

        // Handle Enrollment term
        const enrollmentTerm = loan?.draws?.[loan?.draws?.length - 1]?.enrollmentTerm;
        if (enrollmentTerm) {
          store.commit("fundsModule/setFundingTerm", enrollmentTerm);
        }

        // Handle Enrollment Status
        const enrollmentStatus = loan?.draws?.[loan?.draws?.length - 1]?.enrollmentStatus;
        if (enrollmentStatus) {
          store.commit("fundsModule/setEnrollmentStatus", enrollmentStatus);
        }
      }
    }
    async function getLoanDetails() {
      if (isComplete.value) {
        const res = await $api.applications.getLoans(
          props?.application?.referenceId,
          props?.application?.lenderId,
        );

        if (res.loan) {
          const loan = res.loan;
          const availableToBorrow = res.availableToBorrowAmount;
          const loanBoarded = await $api.originator.loanHasBeenBoarded(
            props?.application?.referenceId,
          );
          let loanVM = {
            id: loan.id,
            draws: loan.draws,
            enrollmentStatus: loan.enrollmentStatus,
            loanAmount: loan.loanAmount,
            referenceId: loan.referenceId,
            loanConfiguration: loan.loanConfiguration,
            loanOriginationSource: loan.loanOriginationSource,
            loanStatusReasonType: loan.loanStatusReasonType,
            loanStatusType: loan.loanStatusType,
            originationSourceLoanId: loan.originationSourceLoanId,
            availableToBorrow: availableToBorrow,
            minimum: loan.draws?.length === 0 ? 1000 : 250,
            loanBoarded: loanBoarded,
            schoolLevelTypeId: props?.application?.schoolConfiguration?.schoolLevelTypeId,
          };
          store.commit("fundsModule/setCurrentLoan", loanVM);
          await getLastRequestedDraw(loan);
          loanExists.value = true;
          gettingData.value = false;
          loading.value = false;
          return loan;
        } else if (res.error === 404) {
          loanExists.value = false;
          return false;
        } else if (res.error === 500 || res.error === true) {
          loanSvcError.value = true;
          loading.value = false;
        }
      }
    }
    return {
      legalPacketGenerated,
      allowZero,
      loanExists,
      applicationStatus,
      appObserver,
      edit,
      error,
      formatMoney,
      fundsRequestBtnText,
      getData,
      gettingData,
      getLoanDetails,
      getPaymentOptionHeader,
      getRateTypeName,
      info,
      initialDrawRequest,
      isComplete,
      loading,
      loanSvcError,
      loanConfiguration,
      modalsClosed,
      previous,
      selectedRatePlan,
      schoolInfo,
      showAlertModal,
      showBorrowAmountModal,
      showFundsRequestModal,
      showLoanConfirmationModal,
      showLoanOptionsModal,
      showRequestFundsButton,
      showSchoolConfirmationModal,
      showTimeoutModal,
      store,
      updateBorrowAmount,
      updateLoanOptions,
      updateSchoolInfo,
      updateLoanConfirmation,
      handleBorrowAmountModal,
      enrollmentStatusName,
      rateAndRepaymentStipsExist,
      sortedApplicationStips,
    };
  },
  components: {
    Tile,
    SchoolConfirmation,
    BorrowAmount,
    LoanOptions,
    TimeoutModal,
    NextButton,
    RequestFundsModalOpal,
    Error,
    CircleLoader,
    OpalModal,
  },
  computed: {
    ...mapState(["configuration", "lenderData", "appData", "schools"]),
    ...mapGetters("fundsModule", ["Loan"]),
    enrollmentTerm(): string {
      let term =
        this.isComplete && this.Loan?.id > 0 && this.Loan?.draws?.length > 0
          ? this.Loan?.draws?.[this.Loan?.draws?.length - 1]?.enrollmentTerm?.description
          : this.appData?.application?.schoolConfiguration?.academicTerm;
      return term || "Not Yet Selected";
    },
    disbursements(): any[] {
      const terms = this.enrollmentTerm.split("-") || [];
      let amount =
        this.isComplete && this.Loan?.draws?.length > 0
          ? this.Loan?.draws?.[this.Loan?.draws?.length - 1]?.requestedAmount
          : this.info?.initialDrawAmount;

      return terms.map((term: string) => {
        return {
          term: term.trim(),
          amount: (amount || 0) / (terms || []).length,
        };
      });
    },
  },
  methods: {
    ...mapActions([
      "setAppData",
      "setApprovedLoanAmount",
      "setSelectedInterestRate",
      "setSelectedRateTypeName",
      "setSelectedRepaymentTypeName",
    ]),
    refreshObservers() {
      if (this.application) {
        let application = this.appData.application;
        this.appObserver = jsonPatch.observe<Application>(application);
        console.log("refreshObservers()", this.appObserver);
      }
    },
    async getLatestInitialDrawInformation() {
      this.info.initialDrawAmount = this.initialDrawRequest?.amount;
      this.info.requestedAmount = this.initialDrawRequest?.amount
        ? this.formatMoney(this.initialDrawRequest?.amount)
        : this.formatMoney(Number(0.0));
      const selectedRatePlanExists =
        this.appData.application?.ratePlans?.length > 0 &&
        this.appData.application?.loanConfiguration?.applicationId;
      if (selectedRatePlanExists) {
        let selectedRatePlanId = this.appData?.application?.loanConfiguration?.ratePlanId;
        let selectedRatePlan = this.appData.application?.ratePlans.find(
          (l: any) => l.id === selectedRatePlanId,
        );
        if (selectedRatePlan) {
          this.selectedRatePlan = selectedRatePlan;
          this.info.interestRate = this.selectedRatePlan?.rate || "Not Yet Selected";
          this.info.rateTypeName = this.getRateTypeName(this.selectedRatePlan?.rateTypeId);
          this.info.repaymentTypeName = this.getPaymentOptionHeader(
            this.appData?.application?.loanConfiguration?.repaymentTypeId,
          );
        }
      } else {
        this.info.interestRate = undefined;
        this.info.rateTypeName = undefined;
        this.info.repaymentTypeName = undefined;
      }
    },
    async setAppInfo() {
      this.gettingData = true;
      const isComplete =
        this.appData?.application?.applicationStatusName?.toString().toLowerCase().trim() ===
        "complete";

      if (isComplete) {
        await this.getLoanDetails();
      }
      if (!isComplete || (isComplete && !this.loanSvcError && !this.loanExists)) {
        // Check for school info
        if (this.appData?.application?.schoolConfiguration?.schoolId) {
          this.info.schoolName = this.appData?.application?.schoolConfiguration?.schoolName;
        } else {
          this.info.schoolName = "Not Yet Selected";
        }
        // Check for academic term
        if (this.appData?.application?.schoolConfiguration?.academicTerm) {
          this.info.academicTerm = this.appData?.application?.schoolConfiguration?.academicTerm;
        } else {
          this.info.academicTerm = "Not Yet Selected";
        }
        // Check for enrollment status
        if (this.appData?.application?.schoolConfiguration?.enrollmentStatus) {
          this.info.enrollmentStatus =
            this.appData?.application?.schoolConfiguration?.enrollmentStatus;
        } else {
          this.info.enrollmentStatus = "Not Yet Selected";
        }
        // Check for initial draw
        let initialDraw = await $api.applications.getInitialDraw(this.appData.application.id);
        const initialDrawExists = initialDraw?.amount && !initialDraw?.error;
        if (initialDrawExists) {
          this.initialDrawRequest = initialDraw;
        }
        this.info.initialDrawAmount = this.initialDrawRequest?.amount;
        this.info.requestedAmount = this.initialDrawRequest?.amount
          ? this.formatMoney(this.initialDrawRequest?.amount)
          : this.formatMoney(Number(0.0));
      }

      // Check for interest rate and repayment
      const selectedRatePlanExists =
        this.appData.application?.ratePlans?.length > 0 &&
        this.appData.application?.loanConfiguration?.applicationId;
      if (selectedRatePlanExists) {
        let selectedRatePlanId = this.appData?.application?.loanConfiguration?.ratePlanId;
        let selectedRatePlan = this.appData.application?.ratePlans.find(
          (l: any) => l.id === selectedRatePlanId,
        );
        if (selectedRatePlan) {
          this.selectedRatePlan = selectedRatePlan;
          this.info.interestRate = this.selectedRatePlan?.rate;
          this.info.rateTypeName = this.getRateTypeName(this.selectedRatePlan?.rateTypeId);
          this.info.repaymentTypeName = this.getPaymentOptionHeader(
            this.appData?.application?.loanConfiguration?.repaymentTypeId,
          );
          this.gettingData = false;
        }
      } else {
        this.info.interestRate = undefined;
        this.info.rateTypeName = undefined;
        this.info.repaymentTypeName = undefined;
      }

      console.log("this.info", this.info);
      this.loading = false;
      this.gettingData = false;
    },
    async complete(event: any) {
      this.loading = true;
      // Handle school confirmation modal
      if (event.school || event?.selectedTerm) {
        // Update info object that will update UI
        this.info.school = event?.school;
        this.info.schoolUndecided = event?.schoolUndecided;
        this.info.academicTerm = event?.selectedTerm;
        this.info.enrollmentTermId = event?.academicTermId;
        this.info.graduationDate = event?.graduationDate;
        this.info.graduationDateYear = event?.graduationDateYear;
        this.info.graduationDateMonth = event?.graduationDateMonth;

        // update appData object
        this.store.dispatch("setSchoolConfig", event);

        await this.updateAppData();
        // Get latest school name
        this.info.schoolName = this.appData.application.schoolConfiguration.schoolName;
        this.info.academicTerm = this.appData.application.schoolConfiguration.academicTerm;

        // Check for initial draw
        let initialDrawRequest = await $api.applications.getInitialDraw(
          this.appData.application.id,
        );
        const initialDrawExists = initialDrawRequest?.amount && !initialDrawRequest?.error;

        // Update initial draw endpoint schoolDOEId and schoolBranchDOEId
        if (initialDrawExists) {
          this.info.school = await $api.school.getSchool(event.school.id);

          let initialFundsRequest = {
            userId: this.applicant?.userId, // last user added
            schoolDOEId: this.info.school.schoolDOEId,
            schoolBranchDOEId: this.info.school.schoolBranchDOEId,
            enrollmentTermId: parseInt(this.info.enrollmentTermId),
            amount: initialDrawRequest.amount || Number(0),
          };
          // Update initial draw
          const response = await $api.applications.addOrUpdateInitialDraw(
            this.application.id,
            initialFundsRequest,
          );
          if (!response.errors || response.applicationId) {
            this.initialDrawRequest = response;
          } else {
            this.error = true;
          }
        }
        // Add an initial draw if one does not exist
        else {
          this.info.school = await $api.school.getSchool(event.school.id);
          let initialFundsRequest = {
            userId: this.applicant?.userId, // last user added
            schoolDOEId: this.info.school.schoolDOEId,
            schoolBranchDOEId: this.info.school.schoolBranchDOEId,
            enrollmentTermId: parseInt(this.info.enrollmentTermId),
            amount: Number(0),
          };
          // Update initial draw
          const response = await $api.applications.addOrUpdateInitialDraw(
            this.application.id,
            initialFundsRequest,
          );

          this.initialDrawRequest = response;
        }
      }

      // Handle borrow amount modal
      if (this.appData.application.schoolConfiguration?.schoolId && event.amount) {
        this.info.school = await $api.school.getSchool(
          this.appData?.application?.schoolConfiguration?.schoolId,
        );
        this.info.requestedAmount = event.amount;

        if ((this.store.state.enrollmentTerms || []).length <= 0) {
          const res = await $api.lookups.getEnrollmentTermsLookups();
          this.store.dispatch("setEnrollmentTerms", res);
        }

        const enrollmentTermId = this.store.state.enrollmentTerms.find(
          (e: any) =>
            e.description === this.appData?.application?.schoolConfiguration?.academicTerm,
        )?.id;
        let initialFundsRequest = {
          userId: this.applicant?.userId,
          schoolDOEId: this.info?.school?.schoolDOEId,
          schoolBranchDOEId: this.info?.school?.schoolBranchDOEId,
          enrollmentTermId: enrollmentTermId,
          amount:
            event.amount ||
            parseInt(event.amount) ||
            parseInt(this.info.requestedAmount) ||
            Number(0),
        };

        // Update initial draw
        const response = await $api.applications.addOrUpdateInitialDraw(
          this.application.id,
          initialFundsRequest,
        );
        // Happy path in CI, QA, UAT, & Prod
        if (!response.errors || response.applicationId) {
          this.initialDrawRequest = response;
        }
        // Error handling
        else {
          this.error = true;
        }
      }

      if (event.selectedPaymentOption) {
        // Update Loan Configuration
        let loanConfigUpdateRequest = {
          applicationId: this.appData.application.id,
          selectedRatePlanId: event?.selectedRatePlanId,
          selectedByApplicantId: event?.selectedByApplicantId,
          repaymentTypeId: event?.selectedPaymentOption?.repaymentTypeId,
        };
        const response = await $api.applications.addOrUpdateAppLoanConfig(loanConfigUpdateRequest);

        // Happy path in CI, QA, UAT, & Prod
        if (!response.errors || response.applicationId) {
          this.info.rateTypeId = response?.rateTypeId;
          this.selectedRatePlan = response;
          this.info.rateTypeId = response?.rateTypeId;
          this.selectedRatePlan = response;
          this.info.interestRate = event?.interestRate;
          this.info.repaymentTypeId = response?.repaymentTypeId;
          this.info.rateTypeName = this.getRateTypeName(event?.rateTypeId);
          this.info.repaymentTypeName = this.getPaymentOptionHeader(response?.repaymentTypeId);
          this.appData.application.loanConfiguration = response;
        }

        // Error handling
        else {
          this.error = true;
        }

        // Check if stip for Rate/Repayment stips exist and get latest stip status
        if (this.rateAndRepaymentStipsExist) {
          await this.store.dispatch("checklistModule/getRateAndRepaymentStips");
        }
      }

      // Set fallback timeout for 30 seconds
      // setTimeout(() => {
      //   this.error = true;
      //   this.loading = false;
      // }, 3000);
      await this.getLatestInitialDrawInformation();
      // Close modals:
      this.showBorrowAmountModal = false;
      this.showSchoolConfirmationModal = false;
      this.showLoanConfirmationModal = false;
      this.showLoanOptionsModal = false;
      this.modalsClosed = true;
      this.loading = false;
    },
    async updateAppData() {
      let application = this.appData.application;
      this.appObserver = jsonPatch.observe<Application>(application);
      const applicationPatch = jsonPatch.generate(this.appObserver);
      console.log("APPLICATION PATCH", applicationPatch);
      const updatedAppData = await $api.applications.updateApplication(
        this.applicant.userId,
        this.appData.application.id,
        applicationPatch,
      );
      console.log("UPDATED APP DATA", updatedAppData);
      if (!updatedAppData.error || updatedAppData.id) {
        await this.store.dispatch("setAppDataApplicationAndApplicants", updatedAppData);
      } else {
        this.error = true;
        this.loading = false;
        alert("Something has gone wrong");
      }
    },
  },
  watch: {
    application() {
      this.refreshObservers();
    },
  },
  mounted() {
    this.refreshObservers();
    this.getData();
    this.setAppInfo();

    console.log("store", this.store);
  },
});
