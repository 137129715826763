import { Module } from "vuex";
import store from ".";
import { Applicant } from "@/models/application";
import { $api } from "@/services/api";
import useChecklistHelpers from "@/use/checklistHelpers";
import { Stipulation } from "@/models/opal";
const { title, processingCategoryTitle, groupItems } = useChecklistHelpers();
import {
  StipulationStatusIds,
  ProcessingCategoryIds,
  PayoffVerificationStipulationTypeIds,
  EsignStipulationTypeIds,
} from "@/config/constants";

function isComplete(items: any) {
  const completed = items.every(
    (subitem: any) =>
      subitem.stipulationStatusId === StipulationStatusIds.COMPLETED ||
      subitem.stipulationStatusId === StipulationStatusIds.CU_OVERRIDE,
  );
  return completed;
}

function replaceSortedItem(arr: Array<any>, title: string, newObj: object) {
  const objWithIdIndex = arr.findIndex(obj => obj.title === title);
  if (objWithIdIndex > -1) {
    arr[objWithIdIndex] = Object.assign({}, newObj);
  }
}

function getApplicant(applicantId: number) {
  return store.state.appData.applicants.find((a: Applicant) => a.id === applicantId) || false;
}

function getEsignViewModel(stip: any) {
  const applicant = getApplicant(stip.applicantId);
  const path = applicant
    ? applicant.applicantTypeName === "Primary Applicant"
      ? "Borrower"
      : "Coborrower"
    : "";

  const viewModel = {
    title: `Final Documents`,
    name: `${path}FinalDocuments`,
    type: "button",
    buttonText: "Click Here to Sign",
    collapsible: store.state.stipsData.length > 1,
    ...stip,
  };
  return viewModel;
}

const checklistModule: Module<any, any> = {
  namespaced: true,
  state: {
    checklistItems: [],
    sortedApplicationStips: {},
    sortedItems: [],
    esignAvailable: false,
    esignCompleted: false,
    showIncomeVerification: undefined as undefined | boolean,
    showCompletedItems: false,
    incomeSources: [],
  },
  getters: {
    checklistItems() {
      const keys = Object.keys(store.state.checklistModule.sortedApplicationStips);
      const result = keys.map((k: string) => {
        if (k === "Final Documentation") {
          Object.values(store.state.checklistModule.sortedApplicationStips[k]).forEach((s: any) => {
            const viewModel = getEsignViewModel(s);
            Object.assign(s, viewModel);
          });

          return {
            title: `Final Documents`,
            items: Object.values(store.state.checklistModule.sortedApplicationStips[k]),
          };
        } else {
          return;
        }
      });
      for (const res of Object.values(result)) {
        if (res) {
          store.dispatch("addChecklistItem", res);
        }
      }
    },
    sortedItems(state: any) {
      return state.sortedItems;
    },
    esignAvailable: (state: any) => {
      return state.esignAvailable;
    },
    esignCompleted: (state: any) => {
      return state.esignCompleted;
    },
    incomeSources: (state: any) => {
      return state.incomeSources;
    },
    finalDocumentationStipExists: (state: any) => {
      const stips = store.state.checklistModule.sortedApplicationStips[
        "Final Documentation"
      ]?.filter(
        (s: any) =>
          s.stipulationTypeId === EsignStipulationTypeIds.ConfirmationEsign &&
          s.applicantId === store.state.appData.currentApplicant.id,
      );
      return stips?.length > 0
        ? stips[0]?.stipulationStatusId === StipulationStatusIds.OPEN
        : false;
    },
  },
  mutations: {
    setSortedStips(state: any, sortedStips: any[]) {
      state.sortedStips = sortedStips;
    },
    setSortedItems(state: any, sorted: any) {
      state.sortedItems = sorted;
    },
    setSortedApplicationStips(state: any, stips: any) {
      state.sortedApplicationStips = stips;
    },
    esignAvailable(state: any, flag: boolean) {
      state.esignAvailable = flag;
    },
    esignComplete(state: any, flag: boolean) {
      state.esignCompleted = flag;
    },
    addChecklistItem(state: any, item: any) {
      state.checklistItems.push(item);
    },
    setChecklistItems(state: any, items: any) {
      state.checklistItems = items;
    },
    setShowCompletedItems(state: any, flag: boolean) {
      state.showCompletedItems = flag;
    },
    updateSortedChecklistItem(state: any, stip: any) {
      state.checklistModule.sortedApplicationStips[stip.title] = stip;
    },
    setIncomeSources(state: any, sources: any) {
      state.checklistModule.incomeSources = sources;
    },
    setShowIncomeVerification(state: any, flag: boolean) {
      state.showIncomeVerification = flag;
    },
  },
  actions: {
    sortStips({ commit }, payload) {
      commit("setSortedStips", payload);
    },
    setSortedItems({ commit }, payload) {
      commit("setSortedItems", payload);
    },
    setSortedApplicationStips({ commit }, payload) {
      commit("setSortedApplicationStips", payload);
    },
    setEsignAvailableFlag({ commit }, payload) {
      commit("esignAvailable", payload);
    },
    setEsignCompleteFlag({ commit }, payload) {
      commit("esignComplete", payload);
    },
    setShowCompletedItems({ commit }, payload) {
      commit("setShowCompletedItems", payload);
    },
    updateSortedChecklistItem({ commit }, payload) {
      commit("updateSortedChecklistItem", payload);
    },
    setIncomeSources({ commit }, payload) {
      commit("setIncomeSources", payload);
    },
    setShowIncomeVerification({ commit }, payload) {
      commit("setShowIncomeVerification", payload);
    },
    getSortedItemCompleteStatusByTitle({ commit, state }, payload) {
      const sortedItem = state.sortedItems.find((item: any) => item.title === payload);

      sortedItem.complete = isComplete(sortedItem.items);
      commit("setSortedItems", state.sortedItems);

      const completedItems = state.sortedItems.filter((item: any) => item.complete);
      commit("setShowCompletedItems", completedItems.length > 0);
    },
    async updateApplicationStipulationStatus({ commit }, payload) {
      const stipulation = store.state.stipsData.find((s: Stipulation) => s.id === payload);
      const stipulationStatusId = store.state.lookupsModule.lookups.stipStatuses.find(
        (s: any) => s.name === `${payload.status}`,
      );
      const userId = store.state.oidcStore.oidcUser.sub;
      const updateStipRequest = {
        stipulationStatusId: stipulationStatusId,
        updatedByUserId: userId,
      };

      const response = await $api.applications.updateApplicationStipulation(
        stipulation.applicationId,
        stipulation.id,
        updateStipRequest,
      );
      console.log("updateApplicationStipulationStatus()", response);
    },
    async getSortedChecklistItems({ commit }) {
      const applicationStips = await $api.applications.getApplicationStips(
        store.state.appData.application?.id,
      );
      if ((applicationStips?.results || []).length > 0) {
        store.dispatch("setStipsData", applicationStips?.results);
        const activeStips = applicationStips?.results.filter(
          (s: any) => s.stipulationStatusId !== StipulationStatusIds.CANCELLED,
        );

        const filteredStips = activeStips?.filter(
          (s: any) =>
            s.processingCategoryName === "RateTerm" ||
            s.processingCategoryName === "EligibilityVerification" ||
            s.processingCategoryName === "Income" ||
            s.processingCategoryName === "Citizenship" ||
            s.processingCategoryName === "SpousalConsent" ||
            (s.processingCategoryId === ProcessingCategoryIds.ESign &&
              s.applicantId === store?.state?.appData?.currentApplicant?.id) ||
            (s.processingCategoryId === ProcessingCategoryIds.PayoffVerification &&
              s.stipulationTypeId === PayoffVerificationStipulationTypeIds.ConfirmPayoffRequest) ||
            (s.processingCategoryId === ProcessingCategoryIds.PayoffVerification &&
              s.stipulationTypeId === PayoffVerificationStipulationTypeIds.LoanAccountStatement) ||
            (s.processingCategoryId === ProcessingCategoryIds.PayoffVerification &&
              s.stipulationTypeId ===
                PayoffVerificationStipulationTypeIds.LoanResponsibilityAffidavit &&
              s.applicantId === store?.state?.appData?.currentApplicant?.id) ||
            (s.processingCategoryId === ProcessingCategoryIds.PayoffVerification &&
              s.stipulationTypeId === PayoffVerificationStipulationTypeIds.PayoffStatement) ||
            (s.processingCategoryId === ProcessingCategoryIds.PayoffVerification &&
              s.stipulationTypeId === PayoffVerificationStipulationTypeIds.ServicerPayoffAddress) ||
            (s.processingCategoryId === ProcessingCategoryIds.PayoffVerification &&
              s.stipulationTypeId ===
                PayoffVerificationStipulationTypeIds.ResponsibilityForUnderPayment &&
              s.applicantId === store?.state?.appData?.currentApplicant?.id) ||
            (s.processingCategoryName === "Identity" &&
              s.stipulationTypeName.toLowerCase() === "StateIssuedID".toLowerCase()) ||
            (s.processingCategoryName === "Identity" &&
              s.stipulationTypeName.toLowerCase() === "Passport".toLowerCase()) ||
            (s.processingCategoryName === "Identity" &&
              s.stipulationTypeName.toLowerCase() === "BirthCertificate".toLowerCase()) ||
            (s.processingCategoryName === "Identity" &&
              s.stipulationTypeName.toLowerCase() === "SchoolID".toLowerCase()) ||
            (s.processingCategoryName === "Identity" &&
              s.stipulationTypeName.toLowerCase() === "SocialSecurityCard".toLowerCase()) ||
            (s.processingCategoryName === "Identity" &&
              s.stipulationTypeName.toLowerCase() === "TaxFormNameSSN".toLowerCase()) ||
            (s.processingCategoryName === "Identity" &&
              s.stipulationTypeName.toLowerCase() === "OtherIDVerification".toLowerCase()) ||
            (s.processingCategoryName === "Identity" &&
              s.stipulationTypeName.toLowerCase() === "ITINLetterOrCard".toLowerCase()) ||
            (s.processingCategoryName === "Identity" &&
              s.stipulationTypeName.toLowerCase() === "ProofOfAddress".toLowerCase()) ||
            (s.processingCategoryName === "Identity" &&
              s.stipulationTypeName.toLowerCase() === "NameVerification".toLowerCase() &&
              s.applicantId === store?.state?.appData?.currentApplicant?.id) ||
            (s.stipulationTypeName.toLowerCase() === "AddressVerification".toLowerCase() &&
              s.applicantId === store?.state?.appData?.currentApplicant?.id) ||
            (s.stipulationTypeName.toLowerCase() === "DateOfBirthVerification".toLowerCase() &&
              s.applicantId === store?.state?.appData?.currentApplicant?.id) ||
            (s.stipulationTypeName.toLowerCase() === "SSNVerification".toLowerCase() &&
              s.applicantId === store?.state?.appData?.currentApplicant?.id) ||
            (s.stipulationTypeName.toLowerCase() === "ExecuteLegalDocPackage".toLowerCase() &&
              s.applicantId === store?.state?.appData?.currentApplicant?.id) ||
            s.stipulationTypeName.toLowerCase() === "ADDMismatch".toLowerCase() ||
            s.stipulationTypeName.toLowerCase() === "SSNMistmatchProofSSN".toLowerCase() ||
            (s.stipulationTypeName.toLowerCase() === "UnfreezeCredit".toLowerCase() &&
              s.applicantId === store?.state?.appData?.currentApplicant?.id) ||
            (s.stipulationTypeName.toLowerCase() === "UnSuppressBureau".toLowerCase() &&
              s.applicantId === store?.state?.appData?.currentApplicant?.id) ||
            (s.stipulationTypeName.toLowerCase() === "ContactCBRCorrection".toLowerCase() &&
              s.applicantId === store?.state?.appData?.currentApplicant?.id) ||
            s.stipulationTypeName.toLowerCase() === "EstablishMembership".toLowerCase(),
        );

        filteredStips.forEach((result: any) => {
          result.processingCategoryTitle = processingCategoryTitle(result);
        });

        const sortedApplicationStips = groupItems(filteredStips, "processingCategoryTitle");
        const stipGroups = Object.keys(sortedApplicationStips);

        const sortedItems = [] as any[];

        const items = stipGroups.map((k: string) => {
          return {
            title: k,
            formattedTitle: title(k),
            complete: isComplete(Object.values(sortedApplicationStips[k])),
            items: Object.values(sortedApplicationStips[k]),
          };
        });

        items?.forEach((item: any) => {
          // check if there is no item present
          const newItem = sortedItems?.find((s: any) => s.title === item.title) === undefined;

          if (newItem === false) {
            replaceSortedItem(sortedItems, item.title, item);
          } else {
            sortedItems?.push(item);
          }
        });

        const completedItems = sortedItems.filter((item: any) => item.complete);
        commit("setSortedItems", sortedItems);
        commit("setShowCompletedItems", completedItems.length > 0);
        commit("setSortedApplicationStips", sortedApplicationStips);
        store.dispatch("checklistModule/getEsignStatus");
      }
    },
    async getRateAndRepaymentStips({ state, commit }) {
      const applicationStips = await $api.applications.getApplicationStips(
        store.state.appData.application?.id,
      );
      console.log("getRateAndRepaymentStips", applicationStips);

      if ((applicationStips?.results || []).length > 0) {
        const activeStips = applicationStips?.results.filter(
          (s: any) => s.stipulationStatusId !== StipulationStatusIds.CANCELLED,
        );
        const filteredStips = activeStips?.filter(
          (s: any) =>
            s.processingCategoryName === "RateTerm" ||
            s.processingCategoryName === "LoanConfiguration",
        );

        filteredStips.forEach((result: any) => {
          result.processingCategoryTitle = processingCategoryTitle(result);
        });

        const sortedApplicationStips = groupItems(filteredStips, "processingCategoryTitle");
        const stipGroups = Object.keys(sortedApplicationStips);
        const sortedItems = state.sortedItems;
        const items = stipGroups.map((k: string) => {
          return {
            title: k,
            formattedTitle: title(k),
            complete: isComplete(Object.values(sortedApplicationStips[k])),
            items: Object.values(sortedApplicationStips[k]),
          };
        });

        items?.forEach((item: any) => {
          // check if there is no item present
          const newItem = sortedItems?.find((s: any) => s.title === item.title) === undefined;

          if (newItem === false) {
            replaceSortedItem(sortedItems, item.title, item);
          } else {
            sortedItems?.push(item);
          }
        });

        const sortedApplicationStipsState = state.sortedApplicationStips;
        sortedApplicationStipsState["Rate and Repayment"] = Object.values(
          sortedApplicationStips["Rate and Repayment"],
        );
        commit("setSortedItems", sortedItems);
        commit("setSortedApplicationStips", sortedApplicationStipsState);
      }
    },
    getEsignStatus({ commit }) {
      const esignAvailableFlag =
        store.state.checklistModule.sortedApplicationStips["Final Documentation"] &&
        store.state.checklistModule.sortedApplicationStips["Final Documentation"].length > 0;
      commit("esignAvailable", esignAvailableFlag);
      if (esignAvailableFlag) {
        const esignStips = store.state.checklistModule.sortedApplicationStips[
          "Final Documentation"
        ].filter(
          (s: any) =>
            (s.stipulationTypeId === EsignStipulationTypeIds.ConfirmationEsign ||
              s.stipulationTypeId === EsignStipulationTypeIds.ExecuteLegalDocPackage) &&
            s.applicantId === store.state.appData.currentApplicant.id,
        );

        const completed = esignStips[0]?.stipulationStatusName === "Completed";
        commit("esignComplete", completed);
      }
    },
  },
};

export default checklistModule;
