import { Module } from "vuex";
import { $api } from "@/services/api";
import {
  RefiApplicationDetailsViewModel,
  RefiLoanViewModel,
  RefiLoanRequest,
  RefiLoanModalViewModel,
} from "@/models/refi";
import { UpdateRefiLoansRequest } from "@/models/vuex";
import store from "@/store/index";

const applicationInfoModule: Module<any, any> = {
  namespaced: true,
  state: {
    applicationInfo: {} as RefiApplicationDetailsViewModel,
    loanDetailsModalActive: false as boolean,
    interestRateAndLoanTermModalActive: false as boolean,
    confirmYourRefinanceDetailsModalActive: false as boolean,
    originalRefiLoans: [] as RefiLoanViewModel[],
    refiLoans: [] as RefiLoanViewModel[],
    loanModal: {} as RefiLoanModalViewModel,
    currentlyActiveModal: "" as string,
    currentlyActiveModals: [] as string[],
    updateRefiLoansError: false as boolean,
    getRefiLoansError: false as boolean,
    updatingRefiLoans: false as boolean,
    savingChanges: false as boolean,
    calculatedEstimatedTotalToRefinance: 0 as number,
  },
  mutations: {
    SET_REFI_LOANS(state: any, refiLoans: Array<RefiLoanViewModel>) {
      state.refiLoans = refiLoans;
    },
    SET_REFI_LOAN_MODAL(state: any, loanModal: RefiLoanModalViewModel) {
      state.loanModal = loanModal;
    },
    SET_UPDATING_REFI_LOANS(state: any, flag: boolean) {
      state.updatingRefiLoans = flag;
    },
    SET_SAVING_CHANGES(state: any, flag: boolean) {
      state.savingChanges = flag;
    },
    SET_UPDATE_REFI_LOANS_ERROR(state: any, flag: boolean) {
      state.updateRefiLoansError = flag;
    },
    SET_GET_REFI_LOANS_ERROR(state: any, flag: boolean) {
      state.getRefiLoansError = flag;
    },
    SET_CURRENTLY_ACTIVE_MODAL(state: any, modal: string) {
      state.currentlyActiveModal = modal;
    },
    DISCARD_CHANGES(state: any) {
      state.applicationInfo.loans = state.originalRefiLoans;
      state.loanModal.loans = state.originalRefiLoans;
    },
    SET_APPLICATION_INFO(state: any, info: RefiApplicationDetailsViewModel) {
      state.applicationInfo = info;
    },
    SET_APPLICATION_INFO_LOANS(state: any, refiLoans: Array<RefiLoanViewModel>) {
      state.applicationInfo.loans = refiLoans;
    },
    SET_ORIGINAL_REFI_LOANS(state: any, loans: any) {
      state.originalRefiLoans = loans;
    },
    TOGGLE_MODAL(state: any, modal: any) {
      // state.currentlyActiveModals.push(modal);
      const index = state.currentlyActiveModals.findIndex((m: string) => m === modal);

      if (index === -1) {
        state.currentlyActiveModals.push(modal);
      }
    },
    UNTOGGLE_MODAL(state: any, modal: any) {
      const index = state.currentlyActiveModals.findIndex((m: string) => m === modal);
      console.log("index", index);
      if (index !== -1) {
        state.currentlyActiveModals.splice(index, 1);
      }
    },
    OPEN_LOAN_DETAILS_MODAL(state: any) {
      state.loanDetailsModalActive = true;
    },
    CLOSE_LOAN_DETAILS_MODAL(state: any) {
      state.loanDetailsModalActive = false;
    },
    OPEN_INTEREST_RATE_AND_LOAN_TERM_MODAL(state: any) {
      state.interestRateAndLoanTermModalActive = true;
    },
    CLOSE_INTEREST_RATE_AND_LOAN_TERM_MODAL(state: any) {
      state.interestRateAndLoanTermModalActive = false;
    },
    OPEN_CONFIRM_YOUR_REFINANCE_DETAILS_MODAL(state: any) {
      state.confirmYourRefinanceDetailsModalActive = true;
    },
    CLOSE_CONFIRM_YOUR_REFINANCE_DETAILS_MODAL(state: any) {
      state.confirmYourRefinanceDetailsModalActive = false;
    },
    CLOSE_ALL_MODALS(state: any) {
      state.loanDetailsModalActive = false;
      state.interestRateAndLoanTermModalActive = false;
      state.confirmYourRefinanceDetailsModalActive = false;
      state.currentlyActiveModal = "";
    },
    UPDATE_REFI_LOAN(state: any, updatedRefiLoan: RefiLoanViewModel) {
      const index = state.loanModal?.loans?.findIndex(
        (loan: any) => loan.id === updatedRefiLoan.id,
      );

      if (index > -1) {
        state.loanModal.loans[index] = updatedRefiLoan;
      }
    },
    SET_CALCULATED_ESTIMATED_TOTAL_TO_REFINANCE(state: any, amount: number) {
      state.calculatedEstimatedTotalToRefinance = amount;
    },
  },
  actions: {
    async getRefiDetails({ commit, state }, applicationId: number) {
      const response = await $api.refi.getRefiDetails(applicationId);

      if (response && !response.error) {
        commit("SET_APPLICATION_INFO", response);
				commit("SET_CALCULATED_ESTIMATED_TOTAL_TO_REFINANCE", response.estimatedTotal);
        state.originalRefiLoans = response?.loans;
      }
    },
    discardChanges({ commit, state }) {
      commit("DISCARD_CHANGES");
    },
    openModal({ commit }, payload) {
      switch (payload) {
        case "LoanDetails":
          commit("OPEN_LOAN_DETAILS_MODAL");
          commit("SET_CURRENTLY_ACTIVE_MODAL", payload);
          break;
        case "InterestRateAndLoanTerm":
          commit("OPEN_INTEREST_RATE_AND_LOAN_TERM_MODAL");
          commit("SET_CURRENTLY_ACTIVE_MODAL", payload);
          break;
        case "ConfirmYourRefinanceDetails":
          commit("OPEN_CONFIRM_YOUR_REFINANCE_DETAILS_MODAL");
          commit("SET_CURRENTLY_ACTIVE_MODAL", payload);
          commit("TOGGLE_MODAL", payload);
          break;
        default:
          commit("SET_CURRENTLY_ACTIVE_MODAL", "");
          return;
      }
    },
    closeModal({ commit, state, dispatch }, payload) {
      commit("CLOSE_ALL_MODALS");
      commit("SET_CURRENTLY_ACTIVE_MODAL", "");

      switch (payload) {
        case "LoanDetails":
          commit("CLOSE_LOAN_DETAILS_MODAL");
          break;
        case "InterestRateAndLoanTerm":
          commit("CLOSE_INTEREST_RATE_AND_LOAN_TERM_MODAL");

          break;
        case "ConfirmYourRefinanceDetails":
          commit("CLOSE_CONFIRM_YOUR_REFINANCE_DETAILS_MODAL");
          commit("UNTOGGLE_MODAL", payload);
          break;
        default:
          return;
      }

      if (state.currentlyActiveModals?.includes("ConfirmYourRefinanceDetails")) {
        dispatch("openModal", "ConfirmYourRefinanceDetails");
      }
    },
    updateRefiLoan({ commit }, updatedRefiLoan: RefiLoanViewModel) {
      commit("UPDATE_REFI_LOAN", updatedRefiLoan);
    },
    setCalculatedEstimatedTotalToRefinance({ commit, state }, amount: number) {
      commit("SET_CALCULATED_ESTIMATED_TOTAL_TO_REFINANCE", amount);
    },
    async getRefiLoanModalData({ state, commit }, payload: any) {
      commit("SET_GET_REFI_LOANS_ERROR", false);

      const refiLoanModalViewModel = await $api.refi.getRefiLoans(payload);

      if (refiLoanModalViewModel) {
        commit("SET_REFI_LOAN_MODAL", refiLoanModalViewModel);
      } else {
        commit("SET_GET_REFI_LOANS_ERROR", true);
      }
    },
    async updateRefiLoans({ commit, dispatch, getters }, payload: UpdateRefiLoansRequest) {
      commit("SET_UPDATING_REFI_LOANS", true);
      commit("SET_UPDATE_REFI_LOANS_ERROR", false);

      const updatedLoans = await $api.refi.updateRefiLoans(
        payload.applicationId,
        payload.applicantId,
        getters.RefiLoansToUpdate,
      );

      if (updatedLoans && updatedLoans?.loans?.length > 0) {
        commit("SET_REFI_LOAN_MODAL", updatedLoans);

        // state.originalRefiLoans = updatedLoans.loans;
        commit("SET_ORIGINAL_REFI_LOANS", updatedLoans.loans);
        commit("SET_APPLICATION_INFO_LOANS", updatedLoans.loans);
        dispatch("closeModal", "LoanDetails");
        commit("SET_UPDATING_REFI_LOANS", false);
      } else {
        commit("SET_UPDATE_REFI_LOANS_ERROR", true);
        commit("SET_UPDATING_REFI_LOANS", false);
      }

      await dispatch("getRefiDetails", payload.applicationId);
    },
  },
  getters: {
    RefiLoansToUpdate: (state: any): Array<RefiLoanRequest> => {
      return state?.loanModal?.loans?.map((r: RefiLoanViewModel) => {
        return {
          id: r.id,
          include: r.include,
        };
      });
    },
    ModalComponent: (state: any): string => {
      return state.currentlyActiveModal;
    },
    RefiLoans: (state: any): Array<RefiLoanViewModel> => {
      return state?.applicationInfo?.loans || [];
    },
    FilteredOriginalRefiLoans: (state: any): Array<RefiLoanViewModel> => {
      return (
        (state?.originalRefiLoans &&
          state?.originalRefiLoans?.filter((l: RefiLoanViewModel) => l.include)) ||
        []
      );
    },
    FilteredRefiLoans: (state: any): Array<RefiLoanViewModel> => {
      return (
        (state?.applicationInfo?.loans &&
          state?.applicationInfo?.loans?.filter((l: RefiLoanViewModel) => l.include)) ||
        []
      );
    },
    ConfirmYourRefinanceDetailsModalOpen: (state: any): boolean => {
      const index = state.currentlyActiveModals.findIndex(
        (m: string) => m === "ConfirmYourRefinanceDetails",
      );

      return index !== -1;
    },
  },
};

export default applicationInfoModule;
