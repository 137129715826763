
import { FieldSchema, FormField, Option } from "@/models/form";
import useValidation from "@/use/validation";
import { defineComponent, PropType } from "vue";
import Icon from "@/components/shared/Icon.vue";

export default defineComponent({
  components: { Icon },
  setup() {
    const { validate, validateForm } = useValidation();
    return {
      validate,
      validateForm,
    };
  },
  props: {
    schema: {
      type: Object as PropType<FieldSchema>,
      required: true,
    },
    modelValue: {
      type: [String, Number, Boolean, Object],
      default: "",
    },
    options: {
      type: Array as PropType<Array<Option>>,
      default: [] as Array<Option>,
    },
    compare: {
      type: [String, Number, Boolean, Object],
      required: false,
    },
  },
  emits: ["update:modelValue"],
  data: () => ({
    field: {} as FormField,
  }),
  computed: {
    hasHelpSlot(): boolean {
      return !!this.$slots.help;
    },
  },
  methods: {},
  beforeMount() {
    // Take in schema and create the form field
    if (this.schema) {
      this.field = {
        ...this.schema,
        touched: false,
        valid: false,
      } as FormField;
    }

    // If we don't do this it defaults booleans as true
    if (this.modelValue === "" || this.modelValue === undefined) {
      this.$emit("update:modelValue", false);
    }
  },
});
