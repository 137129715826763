import { createStore } from "vuex";
import { VuexOidcClientSettings, vuexOidcCreateStoreModule } from "vuex-oidc";
import { oidcSettings, getEnvironment } from "@/config/oidc";
import { WebStorageStateStore } from "oidc-client";
import { LenderData, Configuration } from "../models";
import useSignOut from "@/use/signout";
import useHtmlUtilities from "@/use/htmlUtilities";
import { AppData, Applicant, Application, Address, LoanConfig } from "@/models/application";
import { School } from "@/models/school";
import storePlugins from "@/plugins/storePlugins";
import { EnrollmentTerm, State, LOSType } from "@/models/opal";
import { IncomeSourceViewModel } from "@/models/viewModels";
import { UpdateApplicantSurveyResponsesRequest } from "@/models/vuex";
import { Option } from "@/models/form";
import formsModule from "./formsModule";
import fundsModule from "./fundsModule";
import lookupsModule from "./lookupsModule";
import checklistModule from "./checklistModule";
import modalModule from "./modalModule";
import consentsModule from "@/store/consentsModule";
import applicationInfoModule from "@/store/applicationInfoModule";
import refiLoanConfigurationModule from "@/store/refiLoanConfigurationModule";
import genericFileUploaderModule from "@/store/genericFileUploaderModule";
import { ProductIds } from "@/config/constants";
import { ImageConstants, AddressStateIds, AddressTypeIds } from "@/config/constants";
import { useRoute } from "vue-router";
import useFormatter from "@/use/formatter";
import { $api } from "../services/api";
import store from "@/store";

const environment = getEnvironment();
const { decodeHTMLEncodedStr } = useHtmlUtilities();
const { formattedDateFormat } = useFormatter();

// Check if LID is in the URL
const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());

if (params?.LID || params?.lid) {
  (oidcSettings as any).extraQueryParams = {
    lid: params?.LID || params?.lid,
  };
}

export default createStore({
  plugins: [storePlugins],
  state: {
    configuration: {
      cdnUrl: "https://cusc-lz01-nonprod-cdn.azureedge.net",
      releaseNumber: 504,
      borrowerApplicationUrl: "",
    } as Configuration,
    lenderData: undefined,
    applications: [] as any[],
    appData: {} as AppData,
    schools: [] as School[],
    enrollmentTerms: [] as EnrollmentTerm[],
    states: [] as State[],
    error: false,
    stipsData: [] as any[],
    checklistItems: [] as any[],
    legalPacketGenerated: false as boolean,
    invites: [] as any[],
    incomeSources: [] as any[],
    enrollmentStatuses: [
      { label: "Full-Time", value: 1 },
      { label: "Half-Time", value: 2 },
      { label: "Less Than Half-Time", value: 3 },
    ] as Option[],
  },
  mutations: {
    SET_APPLICATION(state: any, application: Application) {
      state.appData.application = application;
    },
    SET_APP_DATA(state: any, appData: AppData) {
      state.appData = appData;
    },
    SET_APPLICANT_DOCUMENTS(state: any, applicantDocuments: any[]) {
      state.appData.applicantDocuments = applicantDocuments;
    },
    SET_APPLICATION_DOCUMENTS(state: any, applicationDocuments: any[]) {
      state.appData.applicationDocuments = applicationDocuments;
    },
    SET_SCHOOLS(state: any, schools: School[]) {
      state.schools = schools;
    },
    SET_LENDER_DATA(state: any, lenderData: LenderData) {
      // Determine if page should follow light or dark theme
      if (lenderData.primaryColor) {
        const parsedHex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(lenderData.primaryColor);
        const rgb = parsedHex
          ? {
              r: parseInt(parsedHex[1], 16),
              g: parseInt(parsedHex[2], 16),
              b: parseInt(parsedHex[3], 16),
            }
          : null;

        if (rgb !== null) {
          // determine if light or dark theme is needed
          const luminance = Math.round((rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000);

          // Adding else statement just in case client changes. Mainly for testing...
          if (luminance > 118) {
            document.body.classList.add("cuscd-dark");
          } else {
            document.body.classList.remove("cuscd-dark");
          }
        }
      }

      state.lenderData = lenderData;
    },
    SET_CONFIGURATION(state: any, config: Configuration) {
      state.configuration = config;
    },
    SET_APPLICATIONS(state: any, applications: any[]) {
      state.applications = applications;
    },
    SET_ENROLLMENT_TERMS(state: any, enrollmentTerms: any[]) {
      state.enrollmentTerms = enrollmentTerms;
    },
    SET_STATES(state: any, states: any[]) {
      state.states = states;
    },
    SET_ERROR(state: any, error: boolean) {
      state.error = error;
    },
    SET_APP_DATA_APPLICATION_AND_APPLICANTS(state: any, application: AppData) {
      state.appData.application = application.application;
      state.appData.applicants = application.applicants;
    },
    SET_APP_DATA_CURRENT_APPLICANT(state: any, applicant: Applicant) {
      state.appData.currentApplicant = applicant;
    },
    SET_APP_DATA_STIPULATIONS(state: any, stips: any) {
      state.appData.applicationStipulations = stips;
    },
    SET_STIPS_DATA(state: any, data: any[]) {
      state.stipsData = data;
    },
    SET_CHECKLIST_ITEMS(state: any, data: any[]) {
      state.checklistItems = data;
    },
    ADD_CHECKLIST_ITEM(state: any, item: any) {
      state.checklistItems.push(item);
    },
    UPDATE_APPLICATION_LOAN_CONFIGURATION(state: any, loanConfig: LoanConfig) {
      state.appData.application.loanConfiguration = loanConfig;
    },
    UPDATE_APPLICANT_ADDRESSES(state: any, request: any) {
      const applicant = state.appData.applicants.find(
        (a: Applicant) => a.id === request.applicantId,
      );
      if (request.applicantId === state.appData.currentApplicant.id) {
        state.appData.currentApplicant.addresses = request.addresses;
      }
      applicant.addresses = request.addresses;
    },
    SET_LEGAL_PACKET_GENERATED(state: any, flag: boolean) {
      state.legalPacketGenerated = flag;
    },
    SET_INVITES(state: any, invites: boolean) {
      state.invites = invites;
    },
    SET_INCOME_SOURCES(state: any, sources: any) {
      state.incomeSources = sources;
    },
    UPDATE_APPLICANT_SURVEY_RESPONSES(state: any, request: UpdateApplicantSurveyResponsesRequest) {
      const applicant = state.appData.applicants.find(
        (a: Applicant) => a.id === request?.applicantId,
      );

      if (request?.applicantId === state.appData.currentApplicant.id) {
        state.appData.currentApplicant.surveyResponses = request?.surveyResponses;
      }

      applicant.surveyResponses = request?.surveyResponses;
    },
    UPDATE_APPLICANT_MEMBERSHIP(state: any, membership: any) {
      const applicant = state.appData.applicants.find(
        (a: Applicant) => a.id === membership.applicantId,
      );
      if (membership.applicantId === state.appData.currentApplicant.id) {
        state.appData.currentApplicant.membership = membership?.membership;
      }
      applicant.membership = membership;
    },
    SET_SCHOOL_CONFIG(state: any, schoolInfo: any) {
      state.school = schoolInfo.school;
      state.appData.application.schoolConfiguration.schoolUndecided = schoolInfo.schoolUndecided;
      state.appData.application.schoolConfiguration.schoolName = schoolInfo.school.schoolName;
      state.appData.application.schoolConfiguration.schoolId = schoolInfo.school.id;
      state.appData.application.schoolConfiguration.graduationDate = schoolInfo.graduationDate;
      state.appData.application.schoolConfiguration.academicTerm =
        schoolInfo.academicTerm.label || schoolInfo.selectedTerm;

      if (schoolInfo?.enrollmentStatusId) {
        state.appData.application.schoolConfiguration.enrollmentStatus =
          schoolInfo?.enrollmentStatusId;
      }

      if (schoolInfo?.degreeProgramId || schoolInfo?.degreeProgram) {
        state.appData.application.schoolConfiguration.degreeProgram =
          schoolInfo?.degreeProgramId || schoolInfo?.degreeProgram;
      }
    },
  },
  actions: {
    setDefaultLender({ commit }) {
      commit("SET_LENDER_DATA", {
        lenderWebsiteUrl: "https://studentchoice.org",
        lenderSubdomain: "cusc",
        originatorLoginUrl: "https://studentchoice.org",
        lenderOffersRefinance: false,
        refinanceUrl: "https://studentchoice.org",
        applyUrl: "https://studentchoice.org",
        inSchoolTollFreeNumber: "1-800-324-1589",
        refiTollFreeNumber: "1-844-207-9917",
        primaryColor: "#f7941e",
        secondaryColor: "#096fb1",
        redirectOnLogout: false,
        postLogoutRedirectUri: "",
      });
    },
    setLenderData({ commit }, payload) {
      commit("SET_LENDER_DATA", payload);
    },
    setConfiguration({ commit }, payload) {
      commit("SET_CONFIGURATION", payload);
    },
    setApplications({ commit }, payload) {
      commit("SET_APPLICATIONS", payload);
    },
    setAppData({ commit }, payload) {
      commit("SET_APP_DATA", payload);
    },
    setSchools({ commit }, payload) {
      commit("SET_SCHOOLS", payload);
    },
    setEnrollmentTerms({ commit }, payload) {
      commit("SET_ENROLLMENT_TERMS", payload);
    },
    setStates({ commit }, payload) {
      commit("SET_STATES", payload);
    },
    setStips({ commit }, payload) {
      commit("SET_STIPS", payload);
    },
    setApplicationDocuments({ commit }, payload) {
      commit("SET_APPLICATION_DOCUMENTS", payload);
    },
    setApplicantDocuments({ commit }, payload) {
      commit("SET_APPLICANT_DOCUMENTS", payload);
    },
    setError({ commit }, payload) {
      commit("SET_ERROR", payload);
    },
    setAppDataApplicationAndApplicants({ commit }, payload) {
      commit("SET_APP_DATA_APPLICATION_AND_APPLICANTS", payload);
    },
    setAppDataApplicationStipulations({ commit }, payload) {
      commit("SET_APP_DATA_STIPULATIONS", payload);
    },
    setStipsData({ commit }, payload) {
      commit("SET_STIPS_DATA", payload);
    },
    getStip({ commit }, payload) {
      commit("GET_STIP", payload);
    },
    setChecklistItems({ commit }, payload) {
      commit("SET_CHECKLIST_ITEMS", payload);
    },
    addChecklistItem({ commit }, payload) {
      commit("ADD_CHECKLIST_ITEM", payload);
    },
    updateApplicantAddresses({ commit }, payload) {
      commit("UPDATE_APPLICANT_ADDRESSES", payload);
    },
    setApplication({ commit }, payload) {
      commit("SET_APPLICATION", payload);
    },
    setLegalPacketGenerated({ commit }, payload) {
      commit("SET_LEGAL_PACKET_GENERATED", payload);
    },
    setInvites({ commit }, payload) {
      commit("SET_INVITES", payload);
    },
    setIncomeSources({ commit }, payload) {
      commit("SET_INCOME_SOURCES", payload);
    },
    updateApplicantMembership({ commit }, payload) {
      commit("UPDATE_APPLICANT_MEMBERSHIP", payload);
    },
    setSchoolConfig({ commit }, payload) {
      commit("SET_SCHOOL_CONFIG", payload);
    },
    updateLoanConfiguration({ commit }, payload) {
      commit("UPDATE_APPLICATION_LOAN_CONFIGURATION", payload);
    },
    async rehydrateStore({ dispatch }) {
      const route = useRoute();
      const oidcUser = store.getters["oidcStore/oidcUser"];
      let applicationsResponse;

      const applicationResponse = await $api.applications.getUserApplicationByRefId(
        oidcUser.sub,
        route?.params?.referenceId as string,
        0,
      );

      if (applicationResponse?.appData) {
        const applicationDocumentsResponse = await $api.documents.getApplicationDocuments(
          applicationResponse?.appData?.application?.id,
        );
        const applicantDocumentsResponse = await $api.documents.getApplicantDocuments(
          applicationResponse?.currentApplicant[0]?.id,
        );
        const applicationStipsResponse = await $api.applications.getApplicationStips(
          applicationResponse?.appData?.application?.id,
        );
        const generatedDocuments = await $api.documents.getGeneratedDocumentsByRefId(
          route.params?.referenceId as string,
        );

        const activeApplication = applicationResponse?.appData?.application;
        const applicationTypeId =
          applicationResponse?.appData?.application?.metadata?.withCoApplicants === true ? 2 : 1;
        const incomeLimit =
          applicationTypeId === 1
            ? "BorrowerMinIncomeCondApproved"
            : "CoBorrowerMinIncomeCondApproved";
        const limitTypeId = applicationTypeId === 1 ? 9 : 28;
        const dateToCheck = applicationResponse?.appData?.application?.submittedAtUtc
          ? formattedDateFormat(
              applicationResponse?.appData?.application?.submittedAtUtc,
              "YYYY-MM-DD",
            )
          : applicationResponse?.appData?.application?.submittedAtUtc;
        if (dateToCheck !== null) {
          const incomeLimitExists = await $api.programpricing.incomeLimitCheck(
            activeApplication?.lenderId as number,
            activeApplication?.programTypeId,
            activeApplication?.productTypeId,
            limitTypeId,
            dateToCheck as Date,
          );
          dispatch("checklistModule/setShowIncomeVerification", incomeLimitExists);
        }

        const appData = {
          applicants: applicationResponse?.appData?.applicants,
          application: applicationResponse?.appData?.application,
          currentApplicant: applicationResponse?.currentApplicant[0],
          applicationStips: applicationStipsResponse?.results || [],
          applicantDocuments: applicantDocumentsResponse?.error
            ? false
            : applicantDocumentsResponse || [],
          applicationDocuments: applicationDocumentsResponse?.error
            ? false
            : applicationDocumentsResponse || [],
          generatedDocuments: generatedDocuments || [],
        };

        dispatch("setApplications", applicationsResponse);
        dispatch("setAppData", appData);
        console.log("appData", store.state.appData);
      }
    },
    async getApplicantSurveyResponses({ commit }, payload) {
      const surveyResponses = await $api.applicant.getApplicantSurveyResponses(payload);
      if (surveyResponses && surveyResponses?.length > 0) {
        commit("UPDATE_APPLICANT_SURVEY_RESPONSES", { surveyResponses, applicantId: payload });
      }
    },
  },
  getters: {
    showCCPA: (state): boolean => {
      const californiaAddress = (addresses: Address[]) => {
        return (
          addresses.filter(
            (a: Address) =>
              a?.stateId === AddressStateIds.CALIFORNIA &&
              a?.addressTypeId === AddressTypeIds.PermanentAddress,
          )?.length >= 1
        );
      };

      const californiaAddresses = state?.appData?.applicants.map((applicant: Applicant) => {
        return applicant.addresses ? californiaAddress(applicant.addresses) : false;
      });

      return californiaAddresses?.some((el: boolean) => el === true);
    },
    backgroundImageUrl: (state): string => {
      if (state?.appData?.application?.productTypeId === ProductIds.REFI) {
        return state.configuration.refiBackgroundImage;
      } else {
        const path = `/public/Dev/lending-center/${state.configuration.releaseNumber}/images/${ImageConstants.BACKGROUND_GRAD}`;
        return new URL(path, state.configuration.cdnUrl).toString();
      }
    },
    backgroundImageAltText: (state): string => {
      if (state?.appData?.application?.productTypeId === ProductIds.LOC) {
        return "Graduation Image";
      } else {
        return "People Image";
      }
    },
    cuLogoUrl: (state): string => {
      return state.lenderData
        ? new URL(
            `/public/assets/${state.lenderData?.lenderSubdomain || "cusc"}/logo.png`,
            state.configuration.cdnUrl,
          ).toString()
        : "";
    },
    cdnImagesUrl: (state): string => {
      const path = `/public/Dev/lending-center/${state.configuration.releaseNumber}/images`;
      return new URL(path, state.configuration.cdnUrl).toString();
    },
    getCdnImagesUrl: (state: any) => (): string => {
      const path = `/public/Dev/lending-center/${state.configuration.releaseNumber}/images`;
      return new URL(path, state.configuration.cdnUrl).toString();
    },
    configuration: (state: any) => (): any => {
      return state.configuration;
    },
    lenderData: (state: any) => (): any => {
      return state.lenderData;
    },
    appData: (state: any) => (): any => {
      return state.appData;
    },
    enrollmentTerms: (state: any) => (): any => {
      return state.enrollmentTerms;
    },
    states: (state: any) => (): any => {
      return state.states;
    },
    applicantDocuments: (state: any) => (): any => {
      return state.appData.applicantDocuments;
    },
    applicationDocuments: (state: any) => (): any => {
      return state.appData.applicationDocuments;
    },
    applicationStips: (state: any) => (): any => {
      return state.appData.stips;
    },
    previousAddresses: (state: any, applicantId: number): Array<Address> => {
      const applicant = state.appData.applicants.find((a: Applicant) => a.id === applicantId);
      const addresses =
        applicant.addresses.length > 0
          ? applicant.addresses.filter((a: Address) => a.addressTypeName === "Previous Address")
          : applicant.addresses;
      if (addresses.length > 0) {
        addresses.forEach((address: Address) => {
          address.address1 = decodeHTMLEncodedStr(address.address1);
          address.city = decodeHTMLEncodedStr(address.city);
          address.address2 = decodeHTMLEncodedStr(address.address2);
        });
      }

      return addresses || [];
    },
    contactUs: (state: any) => {
      return {
        tollFreeNumber: state.lenderData?.tollFreeNumber,
        email: state.lenderData?.supportEmailAddress
          ? state.lenderData?.supportEmailAddress
          : "member_support@studentchoice.org",
      };
    },
    lenderTollFreeNumberHref: (state: any) => {
      return state.lenderData && state.lenderData?.tollFreeNumber
        ? `+${state.lenderData.tollFreeNumber.replace(/-/g, "")}`
        : "N/A";
    },
    lenderTollFreeNumber: (state: any) => {
      return state.lenderData && state.lenderData?.tollFreeNumber
        ? state.lenderData.tollFreeNumber
        : "N/A";
    },
    productDisplayName: (state: any) => {
      return state.configuration?.productName;
    },
    coApplicantTypeDisplayName: (state: any): string => {
      switch (state.appData?.application?.productTypeId) {
        case ProductIds.LOC:
          return "Co-borrower";
        case ProductIds.REFI:
          return "Cosigner";
        default:
          return "Co-Applicant";
      }
    },
    primaryApplicantTypeDisplayName: (state: any): string => {
      switch (state.appData?.application?.productTypeId) {
        case ProductIds.LOC:
          return "Student Borrower";
        case ProductIds.REFI:
          return "Borrower";
        default:
          return "Primary Applicant";
      }
    },
    refiClassName: (state: any) => {
      return state.appData?.application?.productTypeId === ProductIds.REFI ? "refi" : "";
    },
    SelectedIncomeSourcesCount: (state: any) => {
      return state.incomeSources?.filter((i: IncomeSourceViewModel) => i.isChecked === true)
        ?.length;
    },
		PROGRAM_MINIMUM_LOAN_AMOUNT: (state): number => {
      return state.appData?.application?.metadata?.withCoApplicants === true
        ? state?.lenderData?.borrowerMinLoanAmountJoint
        : state?.lenderData?.borrowerMinLoanAmountSolo;
    },
    PROGRAM_MINIMUM_LOAN_AMOUNT_DISPLAY: (state): string => {
      return state.appData?.application?.metadata?.withCoApplicants === true
        ? state?.lenderData?.borrowerMinLoanAmountJointDisplay
        : state?.lenderData?.borrowerMinLoanAmountSoloDisplay;
    },
  },
  modules: {
    fundsModule,
    modal: modalModule,
    forms: formsModule,
    lookupsModule,
    checklistModule,
    applicationInfoModule,
    refiLoanConfigurationModule,
    genericFileUploaderModule,
		consentsModule,
    oidcStore: vuexOidcCreateStoreModule(
      {
        ...environment,
        ...oidcSettings,
        userStore: new WebStorageStateStore({
          store: window.sessionStorage,
        }),
      } as VuexOidcClientSettings,
      // Optional OIDC store settings
      {
        namespaced: true,
        dispatchEventsOnWindow: true,
      },
      // Optional OIDC event listeners
      {
        userLoaded: user => console.log("OIDC user is loaded:", user),
        userUnloaded: () => console.log("OIDC user is unloaded"),
        accessTokenExpiring: () => console.log("Access token will expire"),
        accessTokenExpired: () => {
          console.log("Access token expired.");
          const { signOut } = useSignOut();
          signOut();
        },
        silentRenewError: () => console.log("Silent Renew Error"),
        userSignedOut: () => {
          console.log("User signed out.");
          const { signOut } = useSignOut();
          signOut();
        },
        oidcError: payload => console.log("OIDC error", payload),
        automaticSilentRenewError: payload =>
          console.log("OIDC automaticSilentRenewError", payload),
      },
    ),
  },
});
